<template>
  <div :class="(type == 4 || type == 1) ? 'col-xl-6 col-lg-6 col-md-6 col-sm-12 py-3 pl-1' : 'row'">
    <div v-if="type == 4">
      <div class="card card-custom card-border cursor-pointer card-with"
        @click="$emit('click', { task: task, type: 4 })"
        style="box-shadow: -5px -5px rgba(100, 100, 100, 0.1), 5px 5px rgba(100, 100, 100, 0.1) !important; background-color: rgb(245, 245, 245) !important;">
        <div class="card-header">
          <div class="card-title">
            <h2 class="card-label w-auto overhead-invoice-title">{{ $t('invoice.overhead_invoice_job') }}</h2>
          </div>
          <div class="card-toolbar">
            <span href="#" class="btn btn-icon btn-danger btn-xs p-0 rounded-full"
              style="border-radius: 50% !important;">{{ task.count }}
            </span>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="flex-40"><b>{{ $t('invoice.date') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ today | momentDateFormat }}
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p class=""><b>{{ $t('invoice.invoice_confirmation_jop_count') }} : </b>
            <p class="text-right px-0 font-weight-bolder"></p>
            <p class="pl-1 ">
              {{ task.count }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="type == 1">
      <div class="card card-custom card-border cursor-pointer card-with"
        @click="$emit('click', { task: task, type: 1 })"
        style="box-shadow: -5px -5px rgba(100, 100, 100, 0.1), 5px 5px rgba(100, 100, 100, 0.1) !important; background-color: rgb(245, 245, 245) !important;">
        <div class="card-header">
          <div class="card-title">
            <h2 class="card-label w-auto">
              {{ $t('invoice.invoice_confirmation_jop') }}</h2>
          </div>
          <div class="card-toolbar">
            <span href="#" class="btn btn-icon btn-danger btn-xs p-0 rounded-full"
              style="border-radius: 50% !important;">{{ task.count }}
            </span>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="flex-40"><b>{{ $t('invoice.date') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ today | momentDateFormat }}
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p class=""><b>{{ $t('invoice.invoice_confirmation_jop_count') }} : </b>
            <p class="text-right px-0 font-weight-bolder"></p>
            <p class="pl-1 ">
              {{ task.count }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 py-3" v-if="type == 2" v-for="item in task">
      <div class="card card-custom card-border cursor-pointer card-with"
        @click="$emit('click', { task: item, type: 2 })"
        style="box-shadow: -5px -5px rgba(100, 100, 100, 0.1), 5px 5px rgba(100, 100, 100, 0.1) !important; background-color: rgb(245, 245, 245) !important;">
        <div class="card-header">
          <div class="card-title">
            <h2 class="card-label w-auto">
              {{ item.order_number }}</h2>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="flex-40"><b>{{ $t('invoice.date') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.waybill_date }}
            </p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="flex-40"><b>İşlem Türü : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              Fatura Kes
            </p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="flex-40"><b>Fatura Türü : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.waybill_type.translations[0].name }}
            </p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="flex-40"><b>Fatura Tipi : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.instruction_type }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 py-3" v-if="type == 3" v-for="item in task">
      <div class="card card-custom card-border cursor-pointer card-with"
        @click="$emit('click', { task: item, id: item.id, type: 3 })"
        style="box-shadow: -5px -5px rgba(100, 100, 100, 0.1), 5px 5px rgba(100, 100, 100, 0.1) !important; background-color: rgb(245, 245, 245) !important;">
        <div class="card-header">
          <div class="card-title">
            <h2 class="card-label w-auto">
              {{ item.order_number }}
            </h2>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="flex-40"><b> {{ $t('packing_list.date') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.created_at }}
            </p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="flex-40"><b> {{ $t('packing_list.in_package') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.in_package }}
            </p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="flex-40"><b>{{ $t('packing_list.box_quantity') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.box_quantity }}
            </p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="flex-40"><b>{{ $t('packing_list.total_amount') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.total_amount }}
            </p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="flex-40"><b>{{ $t('packing_list.request_quantity') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.amount }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import moment from "moment";

export default {
  name: "InvoiceTaskCard",
  data() {
    return {
      today: moment(),

    }
  },
  props: {
    task: {
      required: true,
    },
    type: {
      required: true,
    }
  }
}
</script>

<style scoped type="scss">
.card-with {
  width: 300px !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.overhead-invoice-title{
  font-size:1.200rem !important;
}
.card-header {
  padding: 2rem 2.25rem 0 2.25rem;
  background-color: #ffffff;
  border-bottom: 0px;
}

.card.card-custom>.card-body {
  padding: 0rem 2.25rem 2rem 2.25rem;
}

.card-custom {
  border-radius: 20px !important;
  border: 3px solid $ primary-gray !important;
  box-shadow: 0.5px 1px $ primary-gray-shadow !important;
}

.card {}
</style>
